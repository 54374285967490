import { useEffect, useRef, useState } from "react"
import { Contract, providers, signer } from "ethers"
import { saveAs } from 'file-saver';

const CONTRACT = "0xA91FF8b5A12F827fEbFcE67354F76d019a2f2379";

function buf2hex(buffer) {
  return [...new Uint8Array(buffer)]
    .map(x => x.toString(16).padStart(2, '0'))
    .join('');
}

function hexStringToArrayBuffer(hexString) {
  hexString = hexString.replace(/^0x/, '');
  const pairs = hexString.match(/[\dA-F]{2}/gi);
  const integers = pairs.map(function (s) {
    return parseInt(s, 16);
  });
  const array = new Uint8Array(integers);
  return array.buffer;
}

function App() {
  const contract = useRef();
  const [readName, setReadName] = useState("");

  const storeFile = async (input) => {
    const file = input.target.files[0];
    await contract.current.storeFile(file.name, "0x" + buf2hex(await file.arrayBuffer()))
  }

  const getFile = async () => {
    const blockNumber = await contract.current.getBlockNumberOfFile(readName);
    const filter = await contract.current.filters.Upload(readName)
    const events = await contract.current.queryFilter(filter, parseInt(blockNumber), parseInt(blockNumber));
    const res = events[0].args.fileContent
    const buffer = hexStringToArrayBuffer(res);
    const blob = new Blob([new Uint8Array(buffer, 0, buffer.length)])
    saveAs(blob, readName)
  }

  useEffect(() => {
    ; (async () => {
      const provider = new providers.Web3Provider(window.ethereum, "any");
      await provider.send("eth_requestAccounts", []);
      await provider.send('wallet_addEthereumChain', [{
        chainId: "0x89",
        chainName: 'Matic(Polygon) Mainnet',
        nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
        rpcUrls: ['https://polygon-rpc.com'],
        blockExplorerUrls: ['https://www.polygonscan.com'],
      }]);
      await provider.send('wallet_switchEthereumChain', [{ chainId: "0x89" }]);
      const signer = provider.getSigner();
      contract.current = new Contract(CONTRACT, [
        'function storeFile(string memory fileName, bytes memory fileContent) external',
        'function getBlockNumberOfFile(string calldata fileName) external view returns (uint256)',
        'event Upload(string indexed fileName, bytes fileContent)',
      ], signer);
    })()
  }, [])

  return <div>
    Upload file (cost MATIC) : <br />
    <input type="file" onChange={(e) => storeFile(e)}></input>
    <br /><br /><br />
    Get file (free) :
    <input type="text" placeholder="filename" onChange={(e) => setReadName(e.target.value)}></input>
    <button onClick={() => getFile()}>get file</button> try with "nature.jpg" !
    <br />

  </div>;
}

export default App;
